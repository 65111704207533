import React from 'react'
import styled from 'styled-components'
import TextField from '../../components/TextField'
import LockIcon from '@material-ui/icons/Lock'
import { MenuItem, Grid, Checkbox, FormControlLabel } from '@material-ui/core'
import regions, { regionsON } from '../../constants/regions'
import CriminalCase from './criminalCase'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const AddInfo = styled.span`
    display: block;
    font-size: 0.85rem;
    font-weight: 400;
    margin-bottom: 5px;
`

const Info = ({ saveFieldState, fields }) => {
    const { othersInvolved } = fields
    const { t } = useTranslation()
    return (
        <div style={{ width: '100%' }}>
            <Grid item xs={12} sm={10} style={{ margin: '0 auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <AddInfo>{t('conflictsText')}</AddInfo>
                        <TextField
                            fullWidth
                            helperText={t(
                                'Veuillez entrez le nom des autres parties impliquées'
                            )}
                            id="othersInvolved"
                            name="othersInvolved"
                            className="textInput"
                            label={t('Autres parties impliquées')}
                            autoComplete="off"
                            onChange={saveFieldState}
                            {...othersInvolved}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Info
