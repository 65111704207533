import React from 'react'

export default () => (
    <>
        <h2>Questions fréquentes sur le divorce et la séparation au Québec!</h2>
        <p>
            Entamer des procédures de séparation et de divorce, c’est une étape
            chargée en émotions, ainsi qu’en procédures légales.
            Malheureusement, pour mener un divorce à terme, il est inévitable de
            passer par plusieurs démarches administratives et juridiques, ce qui
            peut soulever bon nombre de questions.
        </p>
        <p>
            N'ayez crainte, nous sommes là pour répondre à toutes les questions
            les plus fréquentes sur le divorce et la séparation au Québec. Et
            mieux encore, on vous met en contact gratuitement avec les avocats
            qualifiés pour vous aider.{' '}
        </p>
        <h3>1) En quoi consiste le divorce au Québec?</h3>
        <p>
            Le divorce est une procédure qui vise à mettre fin au mariage.
            L’objectif du divorce est de dissoudre le lien juridique qui unit
            les conjoints, de procéder au partage des biens du patrimoine
            familial et du régime matrimonial, et de régler toutes les questions
            concernant la garde des enfants et la pension alimentaire.
        </p>
        <p>
            Mais pas si vite! Pour divorcer, il faut être en mesure d’invoquer
            l’un des trois motifs de divorce reconnus, soit la séparation depuis
            un an, l’adultère ou la cruauté physique ou mentale d’un des
            conjoints. Sans motif valable, le divorce ne peut s’enclencher.
        </p>
        <p>
            Ensuite, les futurs ex-époux devront déterminer s’il est possible de
            régler le divorce à l’amiable via un projet d’accord. Dans le cas
            contraire, ils devront recourir à la traditionnelle poursuite en
            divorce devant le tribunal.
        </p>
        <p>
            <strong>
                Sachez que dans tous les cas, il n’est pas nécessaire que les
                deux conjoints consentent au divorce. Un seul des deux peut le
                demander sans problème!
            </strong>
        </p>
        <h3>2) Quelles sont les conditions pour demander le divorce?</h3>
        <p>
            En vertu de la loi, on ne peut pas mettre un terme au mariage pour
            n’importe quel motif. D’ailleurs, pour avoir le droit de divorcer,
            les époux devront invoquer au moins un des trois motifs de divorce
            reconnus par la loi.
        </p>
        <p>
            <strong>
                Voici les motifs reconnus par la loi pour autoriser le divorce
                d’un couple marié :
            </strong>
        </p>
        <h4>1) La séparation depuis au moins 1 an</h4>
        <p>
            <em>
                Les époux peuvent invoquer ce motif s’ils démontrent une réelle
                intention de cesser leur vie commune. Cela peut se produire même
                s’ils habitent encore au même domicile, tant qu’ils prouvent
                qu’ils ont cessé leur vie commune (Ex : Ils ne dorment plus
                ensemble, ils n’ont plus de relations intimes, ils administrent
                leurs affaires séparément.)
            </em>
        </p>
        <h4>2) L’un des conjoints a commis l’adultère</h4>
        <p>
            <em>
                Dans le cadre d’un divorce, l’adultère se définit comme le fait
                d’avoir eu des relations sexuelles avec une personne qui n’est
                pas son époux. Sans grande surprise, seul l’époux qui a été
                trompé peut se servir d’un tel motif pour divorcer.
            </em>
        </p>
        <h4>3) La cruauté physique ou mentale</h4>
        <p>
            <em>
                On parle de cruauté physique lorsqu’une forme de violence
                physique est infligée à l’un des époux, alors que la cruauté
                mentale peut prendre la forme de harcèlement, d’humiliation ou
                encore de mépris.
            </em>
        </p>
        <p>
            Dans la pratique, plusieurs couples choisissent le motif de
            séparation depuis 1 an pour déposer leur demande en divorce, puisque
            cela simplifie la démarche, tant au niveau des procédures que de la
            preuve.
        </p>
        <p>
            <strong>
                Si vous optez pour un divorce à l’amiable, la séparation depuis
                1 an est le seul motif qui pourra être invoqué pour mettre un
                terme à l’union. Tout autre motif impliquera plutôt une
                poursuite en divorce.
            </strong>
        </p>
        <h3>3) Quelles sont les conséquences légales d’un divorce?</h3>
        <p>
            Le divorce est une démarche qui amène des conséquences concrètes sur
            le plan juridique. Vous n’êtes pas seulement séparé dans les faits
            et au quotidien; vous cessez de former une union aux yeux de la loi.
        </p>
        <p>
            <strong>
                Voici les principaux effets légaux qui se produisent lors d’un
                divorce :
            </strong>
        </p>
        <h4>La fin de l’union aux yeux de la loi</h4>
        <p>
            L’union cesse d’être reconnue par les autorités compétentes et le
            lien qui vous unissait à votre époux est maintenant dissout.
        </p>
        <h4>Le partage du patrimoine familial</h4>
        <p>
            <em>
                La valeur des biens du patrimoine familial (Résidence famille,
                véhicules de la famille, meubles et REER), sont séparés en parts
                égales.
            </em>
        </p>
        <h4>La liquidation du régime matrimonial</h4>
        <p>
            <em>
                Les biens du régime matrimonial sont également séparés en vertu
                du régime choisi (Société d’acquêts ou séparation de biens).
            </em>
        </p>
        <h4>La pension alimentaire pour enfants</h4>
        <p>
            <em>
                Le divorce qui implique des enfants engendre également des
                questions sur la pension alimentaire. Celle-ci sera établie en
                fonction du Modèle québécois de fixation des pensions
                alimentaires pour enfants, lequel prévoit un calcul préétabli.
            </em>
        </p>
        <h4>La pension alimentaire pour l’ex-conjoint (Si applicable)</h4>
        <p>
            <em>
                Les ex-conjoints divorcés sont en droit de demander le versement
                d’une pension alimentaire. Pour l’obtenir, le conjoint demandeur
                devra faire la preuve de ses besoins, et la capacité de payer du
                conjoint débiteur devra être démontrée.
            </em>
        </p>
        <h3>4) Combien de temps faut-il pour régler un divorce?</h3>
        <p>
            Cette question fréquente vient avec une réponse peu satisfaisante :
            la durée du règlement d’un divorce peut varier entre quelques
            semaines, quelques mois et même quelques années. Ce sont les
            facteurs propres à votre situation de divorce qui auront la plus
            grande influence sur les délais encourus.
        </p>
        <p>
            Notamment, si vous ne vous entendez pas avec votre ex-conjoint(e)
            sur les modalités du divorce et que le règlement à l’amiable n’est
            pas possible, il faut s’attendre à ce que les délais augmentent
            considérablement.
        </p>
        <p>
            Un divorce à l’amiable peut prendre quelques mois à se régler, alors
            qu’un divorce conflictuel peut prendre jusqu’à quelques années.
        </p>
        <h3>
            5) Est-ce que certaines mesures temporaires peuvent être demandées
            avant le divorce?
        </h3>
        <p>
            Régler le divorce peut prendre des mois, et parfois même des années…
            Certaines questions doivent être réglées beaucoup plus rapidement
            que ça! C’est pourquoi les ordonnances de sauvegarde et les mesures
            provisoires existent, et permettent d’expédier le règlement de
            questions urgentes.
        </p>
        <p>
            <strong>Les ordonnances de sauvegarde </strong>
            est une requête déposée devant la Cour supérieure qui permet de
            régler une question urgente. Une telle ordonnance concerne notamment
            la garde des enfants, l’utilisation de la maison familiale, les
            autorisations de voyage et toute autre question mettant en jeu
            l’intérêt de l’enfant. L’ordonnance prononcée ne peut pas durer plus
            de 6 mois.
        </p>
        <p>
            <strong>Les mesures provisoires </strong>
            visent également à obtenir un jugement temporaire en attendant le
            jugement en divorce, mais pour des questions qui ne correspondent
            pas au critère d’urgence des ordonnances de sauvegarde. Les mesures
            provisoires peuvent être mises en place pour plus de 6 mois, et même
            jusqu’à ce que le jugement en divorce soit rendu.
        </p>
        <p>
            Pour ces deux démarches, les services d’un avocat qualifié en droit
            familial sont nécessaires, puisqu’il s’agit d’une démarche intentée
            devant le tribunal.
        </p>
        <h3>
            6) Qu’est-ce qu’une demande conjointe en divorce sur projet
            d’accord?
        </h3>
        <p>
            <strong>
                La demande conjointe en divorce sur projet d’accord, c’est le
                terme officiel pour parler d’un divorce « à l’amiable ».
            </strong>
            Pour régler un divorce à l’amiable, il est essentiel que les
            conjoints s’entendent sur toutes les modalités de la séparation. Le
            moindre désaccord empêchera la conclusion d’une telle entente à
            l’amiable.
        </p>
        <p>
            De plus, un seul motif peut être invoqué lors d’un divorce à
            l’amiable, soit la séparation depuis 1 an!<strong> </strong>Un
            avocat en droit de la famille rédigera le projet d’accord selon les
            termes convenus entre les époux, et soumettra le tout au tribunal
            afin qu’il soit entériné par le juge.
        </p>
        <h3>7) Est-il avantageux de régler son divorce à l’amiable?</h3>
        <p>
            Sans aucun doute, la réponse est oui! Un divorce à l’amiable est un
            processus beaucoup plus simple et rapide qu’une poursuite en divorce
            traditionnelle. Opter pour la demande en divorce sur projet d’accord
            vient avec plusieurs avantages, tels que :<strong> </strong>
        </p>
        <h4>La diminution des coûts</h4>
        <p>
            En évitant le recours aux tribunaux, vous risquez de sauver des
            milliers de dollars en frais judiciaires, extrajudiciaires et
            administratifs. Ce sont d’importantes sommes d’argent qui peuvent
            être épargnées en réglant à l’amiable.
        </p>
        <h4>L’accélération du processus</h4>
        <p>
            Régler un divorce se fait beaucoup plus rapidement lorsque
            l’harmonie règne entre les conjoints. En évitant les désaccords sur
            le règlement du divorce, vous évitez également bien des délais.
        </p>
        <h4>La réduction de l’animosité</h4>
        <p>
            Régler à l’amiable réduit l’animosité entre les conjoints, et il est
            même possible d’assister à des séances de médiation familiale pour
            faciliter la conclusion d’une entente satisfaisante pour les deux
            conjoints.
        </p>
        <h3>8) Combien ça coûte régler un divorce avec un avocat au Québec?</h3>
        <p>
            Le coût de règlement d’un divorce varie selon plusieurs facteurs au
            Québec, tels que le niveau de désaccord entre les conjoints, la
            durée des procédures et le taux horaire de l’avocat. Le règlement
            d’un divorce à l’amiable commence à partir de 1800$-2000$, alors que
            la moyenne de prix pour un divorce non-contesté varie entre 5000$ et
            7000$.
        </p>
        <p>
            Pour ce qui est de frais reliés à une poursuite en divorce, on peut
            s’attendre à atteindre les 10 000$-13 000$ et même plus selon la
            durée et la complexité des procédures. La seule façon de savoir de
            façon précise combien coûtera votre divorce, c’est d’en parler
            directement avec un avocat spécialisé en droit de la famille.
        </p>
        <h3>9) Comment réduire le coût d’un divorce au Québec?</h3>
        <p>
            Les coûts d’un divorce ne sont pas négligeables, mais heureusement,
            il existe différentes façons de réduire les coûts! La première
            consiste à opter pour une approche à l’amiable afin de limiter le
            nombre d’heures consacrées au règlement du divorce. Cela peut faire
            une différence de plusieurs milliers de dollars en termes
            d’honoraires d’avocat seulement. <strong></strong>
        </p>
        <p>
            Ensuite, il est important de vérifier votre admissibilité à l’aide
            juridique gouvernementale. Cela pourrait vous permettre d’obtenir
            les <strong> </strong>services d’un avocat à moindre coût, et même
            gratuitement selon vos revenus. Et finalement, comme les avocats
            fixent eux-mêmes leurs honoraires, il est important de
            <strong> </strong>comparer plusieurs offres d’avocats, car les
            tarifs sont susceptibles de varier.<strong> </strong>
        </p>
        <h3>
            10) Qu’est-ce que le partage du patrimoine familial lors du divorce?
        </h3>
        <p>
            L’une des conséquences les plus importantes du divorce, c’est le
            partage obligatoire du patrimoine familial. Le patrimoine familial
            est formé par défaut pour tous les couples mariés, et il est
            impossible d’y déroger, même par contrat. Celui-ci comprend les
            résidences familiales, les véhicules de la famille, les meubles du
            ménage ainsi que les fonds de retraite contractés depuis le début du
            mariage.
        </p>
        <p>
            Lors d’un divorce, on procède au partage en parts égales des actifs
            qui composent le patrimoine familial entre les deux époux.
            Toutefois, pour faire partie de ce patrimoine, les biens doivent
            réellement servir à l’usage de la famille!
        </p>
        <p>
            <strong>
                Ce sont donc 4 catégories de biens qui seront partagées entre
                les époux, soit :
            </strong>
        </p>
        <ul>
            <li>
                <em>
                    Les résidences familiales (Ex : Maison principale, chalet
                    familial, etc.)
                </em>
            </li>
            <li>
                <em>
                    Les véhicules de la famille (Doit servir aux déplacements de
                    la famille)
                </em>
            </li>
            <li>
                <em>
                    Les meubles du ménage (Meubles faisant partie d’une
                    résidence familiale)
                </em>
            </li>
            <li>
                <em>
                    Les fonds de retraite (Ceux contractés après le mariage
                    seulement)
                </em>
            </li>
        </ul>
        <p>
            <strong>ATTENTION</strong>– C’est la VALEUR de ces biens qui est
            partageable en parts égales, et non le droit de propriété. Si un
            seul des conjoints est propriétaire de la maison, la propriété de
            celle-ci lui demeure, mais la moitié de sa valeur sera partagée avec
            l’autre conjoint par l’entremise du patrimoine familial.
        </p>
        <p>
            Des ajustements de « plus-value » sont toutefois apportés au moment
            de calculer le partage du patrimoine familial si un conjoint était
            propriétaire de certains de ces biens avant le mariage.
        </p>
        <h3>11) En quoi consiste la liquidation du régime matrimonial?</h3>
        <p>
            <strong>
                Il y a le patrimoine familial qui doit être partagé, mais il ne
                faut pas oublier la liquidation du régime matrimonial.
            </strong>
        </p>
        <p>
            Le régime matrimonial établit les droits et obligations des
            conjoints à l’égard des biens qui ne font pas partie du patrimoine
            familial. Lors d’un divorce, il faut procéder au partage de ces
            actifs entre les conjoints selon le régime établit.
        </p>
        <p>
            Il peut s’agir du régime de la société d’acquêts, de la séparation
            de biens ou encore de la communauté de biens. Les époux établissent
            ce régime par contrat de mariage, et sont libres de choisir celui
            qui leur convient.
        </p>
        <h3>
            12) Mariage et union de FAIT : quelles différences lors d’une
            séparation?
        </h3>
        <p>
            Pour mettre fin au mariage, il faut entamer une procédure de divorce
            qui engendrera certaines conséquences juridiques, comme la
            liquidation du patrimoine familial et du régime matrimonial. Les
            conjoints qui vivent en union de fait n’ont pas besoin de passer à
            travers un tel processus. Cependant,<strong> </strong>les couples en
            union de fait qui se séparent peuvent avoir besoin d’un avocat pour
            régler la question de la garde des enfants et de la pension
            alimentaire.
        </p>
        <p>
            Les conjoints de fait peuvent également avoir besoin d’un avocat
            lors de séances de médiation familiale visant à régler les questions
            relatives à l’intérêt enfants. Et dans tous les cas, l’avocat en
            droit de la famille est un conseiller juridique très compétent pour
            conseiller les conjoints de fait en processus de séparation.
        </p>
        <h3>
            13) Divorce vs. dissolution d’union civile : quelle différence au
            niveau de la procédure?
        </h3>
        <p>
            <strong>
                Mariage et union CIVILE quelles sont différences entre les deux
                institutions?
            </strong>
        </p>
        <p>
            Le mariage est une institution gouvernée à la fois par le droit
            fédéral et par le droit provincial, et qui exige un âge minimal de
            16 ans. L’union civile, quant à elle, est une institution
            provinciale qui nécessite un âge minimal de 18 ans. Cependant, les
            effets juridiques de ces deux institutions sont similaires presqu’en
            tout points.
        </p>
        <p>
            De plus, contrairement au mariage, l’union civile peut simplement
            être dissoute par déclaration commune notariée lorsque les conjoints
            s’entendent pour mettre fin à l’union. Par contre, si la dissolution
            d’union civile implique l’intérêt des enfants, il faudra plutôt
            s’adresser au tribunal!
        </p>
        <h3>
            14) Les conjoints de fait ont-ils des protections juridiques lors
            d’une séparation?
        </h3>
        <p>
            Les conjoints de fait sont très mal protégés à côté des couples
            mariés et des couples unis civilement. Par exemple, ces derniers ne
            bénéficient pas des protections conférées par le patrimoine familial
            et par les régimes matrimoniaux. De plus, les couples en union de
            fait n’ont pas le droit à une pension alimentaire pour ex-conjoints.
            En cas de séparation, il peut donc y avoir un déséquilibre entre les
            situations financières des époux. Si tel est votre situation,
            consultez un avocat en droit familial pour explorer vos options et
            recours.
        </p>
        <h3>
            15) Quel est le rôle de l’avocat en droit de la famille lors d’un
            divorce?
        </h3>
        <p>
            L’avocat en droit familial peut jouer plusieurs rôles lors du
            règlement d’un divorce. Il agit d’abord et avant tout comme
            conseiller juridique aux époux, et les informent de leurs droits
            respectifs à l’occasion du divorce, tout en leur expliquant à chacun
            les options offertes (Ex : divorce à l’amiable, médiation familiale,
            poursuite en divorce, etc.)
        </p>
        <p>
            Ensuite, l’avocat familialiste peut agir comme représentant devant
            le tribunal lors d’une instance de divorce, comme négociateur lors
            d’une tentative de règlement à l’amiable, ou encore, de médiateur en
            matière familiale. L’avocat familialiste peut jouer plusieurs rôles
            afin de s’adapter à votre situation et à vos besoins.
        </p>
        <h3>16) À quoi sert la médiation familiale lors d’un divorce?</h3>
        <p>
            <strong>
                La médiation familiale est un processus qui vise à favoriser le
                règlement d’une séparation en présence d’un tiers, soit l’avocat
                médiateur.
            </strong>
        </p>
        <p>
            Le rôle de ce médiateur est de rester neutre et de favoriser la
            conclusion d’une entente de séparation ou de divorce entre les
            conjoints. Il s’agit d’un processus de plus en plus populaire, mais
            qui nécessite le consentement des deux époux.
        </p>
        <p>
            <strong>Le saviez-vous? </strong>
            Les couples en instance de séparation ont entre 3 et 5 heures de
            médiation familiale gratuite. Ces heures de médiation peuvent
            grandement faciliter le règlement d’un divorce, et prévenir
            d’importants délais.
        </p>
        <h3>
            17) Comment se décide la garde des enfants à l’occasion d’un divorce
            ou d’une séparation?
        </h3>
        <p>
            Les parents ont toujours l’opportunité de s’entendre entre eux sur
            les modalités de la garde des enfants. Lorsque les parents ne
            s’entendent pas, c’est plutôt le juge qui devra trancher la question
            en tenant compte du meilleur intérêt de l’enfant, de son âge, de ses
            besoins moraux et affectifs, de la volonté des parents ainsi que de
            l’opinion de l’enfant lorsqu’il est suffisamment âgé.
        </p>
    </>
)
