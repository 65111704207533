import {
    LockOpen,
    Business,
    Cached,
    Description,
    People,
    EmojiPeople,
    CreditCard,
    FolderOpen,
    House,
    Apartment,
    FlightLand,
    AccountBalance,
    Work,
    AssignmentLate,
    Assignment,
    Category,
} from '@material-ui/icons'

const SMALL = 'smallMandate'

export const lawyerTypesON = [
    {
        name: 'on-admin',
        label: 'Administratif',
        icon: Description,
        children: [
            {
                name: 'on-divisional',
                label: 'Cour divisionnaire',
            },
            {
                name: 'on-social-help',
                label: 'Aide sociale / chômage',
            },
            {
                name: 'on-aivo',
                label: 'AIVO',
            },
            {
                name: 'on-employment',
                label: 'Employment',
            },
            {
                name: 'on-pensions',
                label: 'Rentes / pensions',
            },
            {
                name: 'on-violence',
                label: 'Violence conjugale',
            },
            {
                name: 'on-dsab',
                label: `Refus de prestations d'aide sociale`,
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-business',
        label: 'Affaires',
        icon: Business,
        children: [
            {
                name: 'on-business-corp',
                label: 'Corporatif',
                disabled: true,
            },
            {
                name: 'on-corp-start',
                label: 'Corporatif (démarrage)',
            },
            {
                name: 'on-corp-franchise',
                label: 'Corporatif (franchisage)',
            },
            {
                name: 'on-corp-ma',
                label: 'Corporatif (fusion/achat/vente/transfert)',
            },
            {
                name: 'on-corp-npo',
                label: 'Corporatif (OBNL)',
            },
            {
                name: 'on-corp-reorg',
                label: 'Corporatif (réorganisation)',
            },
            {
                name: 'on-business-litige',
                label: 'Litige',
                disabled: true,
            },
            {
                name: 'on-litige-action',
                label: 'Litige (action sur compte)',
            },
            {
                name: 'on-litige-competition',
                label: 'Litige (concurrence déloyale)',
            },
            {
                name: 'on-litige-shareholders',
                label: 'Litige (entre actionnaires)',
            },
            {
                name: 'on-litige-post-transac',
                label: 'Litige (post-transaction)',
            },
            {
                name: 'on-litige-other',
                label: 'Litige (autre)',
            },
            {
                name: 'on-commercial',
                label: 'Commercial (contractuel)',
            },
            {
                name: 'on-arbitrage',
                label: 'Arbitrage et médiation commerciale',
            },
            {
                name: 'on-ip',
                label: 'Propriété intellectuelle',
            },
            {
                name: 'on-corp-minutes',
                label: 'Livres des minutes',
            },
        ],
    },
    {
        name: 'on-criminal',
        label: 'Criminel et pénal',
        jh: true,
        icon: LockOpen,
        certRequired: true,
        children: [
            {
                name: 'on-criminal-crim',
                label: 'Criminel',
                disabled: true,
            },
            {
                name: 'on-crim-firearm',
                label: 'Criminel (arme à feu)',
            },
            {
                name: 'on-crim-person',
                label: 'Criminel (contre la personne et la réputation)',
            },
            {
                name: 'on-crim-prop',
                label: 'Criminel (contre les droits de propriété)',
            },
            {
                name: 'on-crim-fraud',
                label: 'Criminel (fraude)',
            },
            {
                name: 'on-crim-youth',
                label: 'Criminel (jeune contrevenant)',
            },
            {
                name: 'on-crim-transport',
                label: 'Criminel (moyen de transport)',
            },
            {
                name: 'on-crim-waiver',
                label: 'Criminel (pardon/waiver)',
            },
            {
                name: 'on-crim-sex',
                label: 'Criminel (sexuel)',
            },
            {
                name: 'on-crim-stup',
                label: 'Criminel (stupéfiant)',
            },
            {
                name: 'on-criminal-penal',
                label: 'Pénal',
                disabled: true,
            },
            {
                name: 'on-penal-hunting',
                label: 'Pénal (chasse)',
            },
            {
                name: 'on-penal-enterprise',
                label: 'Pénal (entreprise)',
            },
            {
                name: 'on-penal-environment',
                label: 'Pénal (environnement)',
            },
            {
                name: 'on-penal-road',
                label: 'Pénal (infraction de la route)',
                blockType: SMALL,
            },
            {
                name: 'on-penal-other',
                label: 'Infraction pénal (autre)',
                blockType: SMALL,
            },
            {
                name: 'on-theft-5000-less',
                label: 'Vol de 5000$ ou moins',
                paralegal: true,
            },
            {
                name: 'on-crim-harassment',
                label: 'Harcèlement criminel ',
            },
            {
                name: 'on-crim-assault',
                label: 'Voies de fait',
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-rights',
        label: 'Droits et libertés',
        jh: true,
        icon: EmojiPeople,
        children: [
            {
                name: 'on-rights-liberty',
                label: 'Droits et libertés',
                disabled: true,
            },
            {
                name: 'on-rights-native',
                label: 'Autochtone',
                blockType: SMALL,
                certRequired: true,
            },
            {
                name: 'on-rights-constitution',
                label: 'Constitution et chartes',
            },
            {
                name: 'on-rights-protection',
                label: 'Personnes vulnérables (curatelle, tutelle, conseil au majeur)',
                blockType: SMALL,
                certRequired: true,
            },
            {
                name: 'on-rights-care',
                label: 'Soin et garde en établissement',
                blockType: SMALL,
                certRequired: true,
            },
            {
                name: 'on-rights-tribunal',
                label: `Tribunal des droits de la personne de l’Ontario`,
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-bankruptcy',
        label: 'Faillite et insolvabilité',
        icon: CreditCard,
        children: [
            {
                name: 'on-bankruptcy-enterprise',
                label: 'Entreprise',
            },
            {
                name: 'on-bankruptcy-person',
                label: 'Particulier',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'on-family',
        label: 'Famille et jeunesse',
        jh: true,
        icon: People,
        certRequired: true,
        children: [
            {
                name: 'on-family-family',
                label: 'Divorce/Séparation',
                paralegal: true,
            },
            {
                name: 'on-family-youth',
                label: 'Jeunesse',
                blockType: SMALL,
            },
            {
                name: 'on-family-mediation',
                label: 'Médiation familiale',
                blockType: SMALL,
            },
            {
                name: 'on-violence',
                label: 'Violence conjugale',
            },
            {
                name: 'on-family-contested',
                label: 'Dossiers non-contestés',
                paralegal: true,
            },
            {
                name: 'on-family-child-spousal-over-150k',
                label: 'Pension alimentaire (revenu de plus de 150 000$)',
            },
            {
                name: 'on-family-child-spousal-under-150k',
                label: 'Pension alimentaire (revenu de 150 000$ et moins)',
                paralegal: true,
            },
            {
                name: 'on-family-custody',
                label: 'Garde pour enfant',
            },
        ],
    },
    {
        name: 'on-fiscal',
        label: 'Fiscalité et taxes',
        icon: FolderOpen,
        children: [
            {
                name: 'on-fiscal-enterprise',
                label: 'Entreprise',
            },
            {
                name: 'on-fiscal-person',
                label: 'Particulier',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'on-realestate',
        label: 'Immobilier',
        icon: House,
        children: [
            {
                name: 'on-construction',
                label: 'Construction',
            },
            {
                name: 'on-condo',
                label: 'Copropriété',
                blockType: SMALL,
            },
            {
                name: 'on-realestate-title',
                label: 'Titre, bornage, servitude, etc',
            },
            {
                name: 'on-realestate-vice',
                label: 'Vice caché',
            },
            {
                name: 'on-realestate-transaction',
                label: 'Achat/Vente',
            },
        ],
    },
    {
        name: 'on-rental',
        label: 'Logement',
        icon: Apartment,
        jh: true,
        children: [
            {
                name: 'on-rental-landlord',
                label: 'Propriétaire',
                paralegal: true,
            },
            {
                name: 'on-rental-renter',
                label: 'Locataire',
                blockType: SMALL,
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-immigration',
        label: 'Immigration et citoyenneté',
        icon: FlightLand,
        paralegal: true,
        children: [
            {
                name: 'on-immigration-sponsorship',
                label: 'Parrainage',
                paralegal: true,
            },
            {
                name: 'on-immigration-work-permit',
                label: `Permis d'étude et de travail`,
                paralegal: true,
            },
            {
                name: 'on-immigration-refugee',
                label: 'Réfugié',
                paralegal: true,
            },
            {
                name: 'on-immigration-permanent',
                label: 'Résidence permanente',
                paralegal: true,
            },
            {
                name: 'on-immigration-contest',
                label: 'Contestation (contrôle et appel)',
                paralegal: true,
            },
            {
                name: 'on-immigration-other',
                label: 'Autre',
                blockType: SMALL,
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-municipal',
        label: 'Municipal',
        icon: AccountBalance,
        children: null,
        topLevel: true,
    },
    {
        name: 'on-responsability',
        label: 'Responsabilité civile',
        icon: AssignmentLate,
        children: [
            {
                name: 'on-responsability-general',
                label: 'Générale',
            },
            {
                name: 'on-responsability-slander',
                label: 'Diffamation / atteinte à la réputation',
                blockType: SMALL,
            },
            {
                name: 'on-responsability-injury',
                label: 'Blessure corporelle',
            },
            {
                name: 'on-responsability-medical',
                label: 'Erreur médicale',
            },
            {
                name: 'on-responsability-pro',
                label: 'Professionnelle et disciplinaire',
            },
            {
                name: 'on-responsability-neighborhood',
                label: 'Trouble de voisinage',
                blockType: SMALL,
            },
            {
                name: 'on-responsability-sabia',
                label: `Demandes d'indemnités d'accident couvertes par Loi sur les Assurances`,
                paralegal: true,
            },
        ],
    },
    {
        name: 'on-succession',
        label: 'Succession',
        children: null,
        topLevel: true,
        icon: Cached,
        children: [
            {
                name: 'on-estate-admin',
                label: 'Administration de succession',
            },
            {
                name: 'on-estate-litigation',
                label: 'Litige successoral',
            },
            {
                name: 'on-estate-wills',
                label: 'Testaments',
            },
        ],
    },
    {
        name: 'on-work',
        label: 'Travail',
        icon: Work,
        children: [
            {
                name: 'on-work-employee',
                label: 'Employé',
            },
            {
                name: 'on-work-employer',
                label: 'Employeur',
            },
            {
                name: 'on-wsib',
                label: 'WSIB',
            },
            {
                name: 'on-work-termination',
                label: 'Congédiement',
            },
        ],
    },
    {
        name: 'on-civil',
        label: 'Civil (général)',
        icon: Assignment,
        children: [
            {
                name: 'on-colAction',
                label: 'Action collective',
            },
            {
                name: 'on-civil-account',
                label: 'Action sur compte',
                blockType: SMALL,
            },
            {
                name: 'on-insurance',
                label: 'Assurance',
                disabled: true,
            },
            {
                name: 'on-civil-prop-insurance',
                label: 'Assurance (biens)',
            },
            {
                name: 'on-civil-health-insurance',
                label: 'Assurance (personne)',
            },
            {
                name: 'on-consumer',
                label: 'Consommation',
                blockType: SMALL,
            },
            {
                name: 'on-contracts',
                label: 'Contrats, vente et louage',
                blockType: SMALL,
            },
            {
                name: 'on-civil-banking',
                label: 'Bancaire',
                blockType: SMALL,
            },
            {
                name: 'on-other',
                label: 'Autre/Ne sais pas',
                icon: Category,
                topLevel: true,
                children: null,
            },
            {
                name: 'on-civil-small-claims',
                label: 'Cour des petites créances',
                paralegal: true,
            },
        ],
    },
]

export const lawyerTypes = [
    {
        name: 'family',
        label: 'Famille et jeunesse',
        jh: true,
        icon: People,
        children: [
            {
                name: 'family-family',
                label: 'Famille',
            },
            {
                name: 'family-youth',
                label: 'Jeunesse',
                blockType: SMALL,
            },
            {
                name: 'family-mediation',
                label: 'Médiation familiale',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'business',
        label: 'Affaires',
        icon: Business,
        children: [
            {
                name: 'business-corp',
                label: 'Corporatif',
                disabled: true,
            },
            {
                name: 'corp-start',
                label: 'Corporatif (démarrage)',
            },
            {
                name: 'corp-franchise',
                label: 'Corporatif (franchisage)',
            },
            {
                name: 'corp-ma',
                label: 'Corporatif (fusion/achat/vente/transfert)',
            },
            {
                name: 'corp-npo',
                label: 'Corporatif (OBNL)',
            },
            {
                name: 'corp-reorg',
                label: 'Corporatif (réorganisation)',
            },
            {
                name: 'business-litige',
                label: 'Litige',
                disabled: true,
            },
            {
                name: 'litige-action',
                label: 'Litige (action sur compte)',
            },
            {
                name: 'litige-competition',
                label: 'Litige (concurrence déloyale)',
            },
            {
                name: 'litige-shareholders',
                label: 'Litige (entre actionnaires)',
            },
            {
                name: 'litige-post-transac',
                label: 'Litige (post-transaction)',
            },
            {
                name: 'litige-other',
                label: 'Litige (autre)',
            },
            {
                name: 'commercial',
                label: 'Commercial (contractuel)',
            },
            {
                name: 'arbitrage',
                label: 'Arbitrage et médiation commerciale',
            },
            {
                name: 'ip',
                label: 'Propriété intellectuelle',
            },
            {
                name: 'corp-minutes',
                label: 'Livres des minutes',
            },
        ],
    },
    {
        name: 'criminal',
        label: 'Criminel et pénal',
        jh: true,
        icon: LockOpen,
        children: [
            {
                name: 'criminal-crim',
                label: 'Criminel',
                disabled: true,
            },
            {
                name: 'crim-firearm',
                label: 'Criminel (arme à feu)',
            },
            {
                name: 'crim-person',
                label: 'Criminel (contre la personne et la réputation)',
            },
            {
                name: 'crim-prop',
                label: 'Criminel (contre les droits de propriété)',
            },
            {
                name: 'crim-fraud',
                label: 'Criminel (fraude)',
            },
            {
                name: 'crim-youth',
                label: 'Criminel (jeune contrevenant)',
            },
            {
                name: 'crim-transport',
                label: 'Criminel (moyen de transport)',
            },
            {
                name: 'crim-waiver',
                label: 'Criminel (pardon/waiver)',
            },
            {
                name: 'crim-sex',
                label: 'Criminel (sexuel)',
            },
            {
                name: 'crim-stup',
                label: 'Criminel (stupéfiant)',
            },
            {
                name: 'criminal-penal',
                label: 'Pénal',
                disabled: true,
            },
            {
                name: 'penal-hunting',
                label: 'Pénal (chasse)',
            },
            {
                name: 'penal-enterprise',
                label: 'Pénal (entreprise)',
            },
            {
                name: 'penal-environment',
                label: 'Pénal (environnement)',
            },
            {
                name: 'penal-road',
                label: 'Pénal (infraction de la route)',
                blockType: SMALL,
            },
            {
                name: 'penal-other',
                label: 'Pénal (autre)',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'realestate',
        label: 'Immobilier',
        icon: House,
        children: [
            {
                name: 'construction',
                label: 'Construction',
            },
            {
                name: 'condo',
                label: 'Copropriété',
                blockType: SMALL,
            },
            {
                name: 'realestate-title',
                label: 'Titre, bornage, servitude, etc',
            },
            {
                name: 'realestate-vice',
                label: 'Vice caché',
            },
            {
                name: 'realestate-transaction',
                label: 'Achat/Vente',
            },
        ],
    },
    {
        name: 'immigration',
        label: 'Immigration et citoyenneté',
        icon: FlightLand,
        children: [
            {
                name: 'immigration-sponsorship',
                label: 'Parrainage',
            },
            {
                name: 'immigration-work-permit',
                label: `Permis d'étude et de travail`,
            },
            {
                name: 'immigration-refugee',
                label: 'Réfugié',
            },
            {
                name: 'immigration-permanent',
                label: 'Résidence permanente ',
            },
            {
                name: 'immigration-contest',
                label: 'Contestation (contrôle et appel)',
            },
            {
                name: 'immigration-other',
                label: 'Autre',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'admin',
        jh: true,
        label: 'Administratif',
        icon: Description,
        children: [
            {
                name: 'admin-social',
                label: 'Aide sociale / chômage',
                blockType: SMALL,
            },
            {
                name: 'admin-cnesst',
                label: 'CNESST',
            },
            {
                name: 'admin-cptaq',
                label: 'CPTAQ',
            },
            {
                name: 'admin-saaq',
                label: 'SAAQ',
            },
            {
                name: 'admin-ivac',
                label: 'IVAC',
                blockType: SMALL,
            },
            {
                name: 'admin-rentes',
                label: 'Rentes / Pension',
                blockType: SMALL,
            },
            {
                name: 'admin-scolaire',
                label: 'Scolaire',
                blockType: SMALL,
            },
            {
                name: 'admin-transport',
                label: 'Transport',
            },
            {
                name: 'admin-autres',
                label: 'Autres',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'responsability',
        label: 'Responsabilité civile',
        icon: AssignmentLate,
        children: [
            {
                name: 'responsability-general',
                label: 'Générale',
            },
            {
                name: 'responsability-slander',
                label: 'Diffamation / atteinte à la réputation',
                blockType: SMALL,
            },
            {
                name: 'responsability-injury',
                label: 'Blessure corporelle',
            },
            {
                name: 'responsability-medical',
                label: 'Erreur médicale',
            },
            {
                name: 'responsability-pro',
                label: 'Professionnelle et disciplinaire',
            },
            {
                name: 'responsability-neighborhood',
                label: 'Trouble de voisinage',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'work',
        label: 'Travail',
        icon: Work,
        children: [
            {
                name: 'work-employee',
                label: 'Employé',
            },
            {
                name: 'work-employer',
                label: 'Employeur',
            },
            {
                name: 'work-termination',
                label: 'Congédiement',
            },
        ],
    },
    {
        name: 'civil',
        label: 'Civil (général)',
        icon: Assignment,
        children: [
            {
                name: 'colAction',
                label: 'Action collective',
            },
            {
                name: 'civil-account',
                label: 'Action sur compte',
                blockType: SMALL,
            },
            {
                name: 'insurance',
                label: 'Assurance',
                disabled: true,
            },
            {
                name: 'civil-prop-insurance',
                label: 'Assurance (biens)',
            },
            {
                name: 'civil-health-insurance',
                label: 'Assurance (personne)',
            },
            {
                name: 'consumer',
                label: 'Consommation',
                blockType: SMALL,
            },
            {
                name: 'contracts',
                label: 'Contrats, vente et louage',
                blockType: SMALL,
            },
            {
                name: 'civil-banking',
                label: 'Bancaire',
                blockType: SMALL,
            },
        ],
    },

    {
        name: 'fiscal',
        label: 'Fiscalité et taxes',
        icon: FolderOpen,
        children: [
            {
                name: 'fiscal-enterprise',
                label: 'Entreprise',
            },
            {
                name: 'fiscal-person',
                label: 'Particulier',
                blockType: SMALL,
            },
        ],
    },

    {
        name: 'rental',
        label: 'Logement',
        icon: Apartment,
        jh: true,
        children: [
            {
                name: 'rental-landlord',
                label: 'Propriétaire',
            },
            {
                name: 'rental-renter',
                label: 'Locataire',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'succession',
        label: 'Succession',
        children: null,
        topLevel: true,
        icon: Cached,
        children: [
            {
                name: 'estate-admin',
                label: 'Administration de succession',
            },
            {
                name: 'estate-litigation',
                label: 'Litige successoral',
            },
            {
                name: 'estate-wills',
                label: 'Testaments',
            },
        ],
    },
    {
        name: 'rights',
        label: 'Droits et libertés',
        jh: true,
        icon: EmojiPeople,
        children: [
            {
                name: 'rights-liberty',
                label: 'Droits et libertés',
                disabled: true,
            },
            {
                name: 'rights-native',
                label: 'Autochtone',
                blockType: SMALL,
            },
            {
                name: 'rights-constitution',
                label: 'Constitution et chartes',
            },
            {
                name: 'rights-protection',
                label: 'Personnes vulnérables (curatelle, tutelle, conseil au majeur)',
                blockType: SMALL,
            },
            {
                name: 'rights-care',
                label: 'Soin et garde en établissement',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'bankruptcy',
        label: 'Faillite et insolvabilité',
        icon: CreditCard,
        children: [
            {
                name: 'bankruptcy-enterprise',
                label: 'Entreprise',
            },
            {
                name: 'bankruptcy-person',
                label: 'Particulier',
                blockType: SMALL,
            },
        ],
    },
    {
        name: 'municipal',
        label: 'Municipal',
        icon: AccountBalance,
        children: null,
        topLevel: true,
    },

    {
        name: 'other',
        label: 'Autre/Ne sais pas',
        icon: Category,
        topLevel: true,
        children: null,
    },
]

export default lawyerTypes

export const checkIfParentOf = (possibleParent, possibleChild) => {
    if (
        flatMap[possibleChild] &&
        flatMap[possibleChild].parent === possibleParent
    ) {
        return true
    }
    return false
}

export const adminLawyerTypes = (function () {
    const adminTypes = []
    lawyerTypes.forEach((type) => {
        if (type.children) {
            adminTypes.push(...type.children)
        } else {
            adminTypes.push(type)
        }
    })
    return adminTypes
})()

export const allLawyerTypes = (function () {
    const adminTypes = []
    lawyerTypes.forEach((type) => {
        adminTypes.push({
            name: type.name,
            label: type.label,
            parent: true,
        })

        if (type.children) {
            adminTypes.push(...type.children)
        }
    })
    return adminTypes
})()

export const userLawyerTypesMappings = (function () {
    const mappings = {}
    lawyerTypes.forEach((type) => {
        mappings[type.label] = type.name
    })
    return mappings
})()

function mapTypes(types, newMap) {
    types.forEach((type) => {
        newMap[type.name] = type.label
        if (type.children) {
            type.children.forEach((childType) => {
                newMap[childType.name] = childType.label
            })
        }
    })
}

export const lawyerTypesMappings = (function () {
    const mappings = {}
    mapTypes(lawyerTypes, mappings)
    mapTypes(lawyerTypesON, mappings)
    return mappings
})()

export const lawyerTypesAdminMappings = (function () {
    const mappings = {}
    lawyerTypes.forEach((type) => {
        if (type.children) {
            type.children.forEach((childType) => {
                mappings[childType.name] = childType.label
            })
        } else {
            mappings[type.name] = type.label
        }
    })
    return mappings
})()

const flatMap = (function () {
    const mappings = {}
    lawyerTypes.forEach((type) => {
        if (type.children) {
            type.children.forEach((childType) => {
                mappings[childType.name] = {
                    ...childType,
                    parent: type.name,
                    jh: type.jh,
                }
            })
            mappings[type.name] = type
        } else {
            mappings[type.name] = type
        }
    })
    lawyerTypesON.forEach((type) => {
        if (type.children) {
            type.children.forEach((childType) => {
                mappings[childType.name] = {
                    ...childType,
                    parent: type.name,
                    jh: type.jh,
                }
            })
            mappings[type.name] = type
        } else {
            mappings[type.name] = type
        }
    })
    return mappings
})()

export const checkBlockType = (revenueCode, juridicalHelp, lawyerType) => {
    const lawyerTypeDetails = lawyerTypesFlatMap[lawyerType]
    if (!lawyerTypeDetails) {
        console.log(lawyerType)
        return 'normal'
    }
    if (revenueCode <= 1 || juridicalHelp) {
        if (lawyerTypeDetails?.jh && juridicalHelp) {
            return 'juridicalHelp'
        }
        return 'smallMandate'
    } else if (lawyerTypeDetails?.blockType === 'smallMandate') {
        return 'smallMandate'
    }
    return 'normal'
}

export const lawyerTypesFlatMap = flatMap
