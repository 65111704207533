import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import currency from '../../utils/currency'

import {
    Grid,
    Select,
    MenuItem,
    Input,
    FormControl,
    InputLabel,
    Button as MaterialButton,
} from '@material-ui/core'
import revenueRanges, {
    revenueRangesOrderedKeys,
} from '../../constants/revenueRanges'
import TextField from '../../components/TextField'
import ButtonRow from '../../components/ButtonRow'

const Button = styled(MaterialButton)`
    && {
        height: 50px;
        border-radius: 30px;
        width: 100%;
        .MuiFormControl {
            margin-top: 10px;
        }
    }
`

const statuses = {
    QC: {
        'Personne seule': 27755,
        'Personne seule et 1 enfant à charge': 33960,
        'Personne seule et 2 enfants ou plus à charge': 36253,
        Conjoints: 38628,
        'Conjoints et 1 enfant à charge': 43220,
        'Conjoints et 2 enfants ou plus à charge': 45515,
    },
    ON: {
        '1 personne': 22720,
        '2 personnes': 32131,
        '3 personnes': 39352,
        '4 personnes': 45440,
        '5+ personnes': 50803,
    },
}

const juriPopStatuses = {
    'Personne seule': 44588,
    'Personne seule et 1 enfant à charge': 54539,
    'Personne seule et 2 enfants ou plus à charge': 58222,
    Conjoints: 62047,
    'Conjoints et 1 enfant à charge': 69420,
    'Conjoints et 2 enfants ou plus à charge': 73108,
}

const showAmountsButtons = (province, revenueRange, status) => {
    if (
        !showLegalAidChoice(province, revenueRange, status) ||
        province !== 'QC'
    ) {
        return false
    }
    const { min, max } = revenueRanges?.[revenueRange]
    const juriPopMax = juriPopStatuses?.[status]
    if (juriPopMax <= max && juriPopMax >= min) {
        return true
    }
    return false
}

const showRevenueLowerThan = (province, revenueRange, status) => {
    if (!showLegalAidChoice(province, revenueRange, status)) {
        return false
    }
    const { min, max } = revenueRanges?.[revenueRange]
    const legalAidMax = statuses?.[province]?.[status]
    if (legalAidMax <= max && legalAidMax >= min) {
        return true
    }
    return false
}

const showLegalAidChoice = (province, revenueRange, status) => {
    if (!revenueRange || province === '' || !status) {
        return false
    }
    return true
}

const isLegalAidEligible = (province, revenueRange, status) => {
    if (!showLegalAidChoice(province, revenueRange, status)) {
        return undefined
    }
    const { min, max } = revenueRanges?.[revenueRange]
    const legalAidMax = statuses?.[province]?.[status]
    if (legalAidMax <= max && legalAidMax >= min) {
        return undefined
    }
    if (legalAidMax >= max) {
        return true
    }

    return false
}

const mightBeEligible = (province, revenueRange) => {
    const { min, max } = revenueRanges?.[revenueRange]
    const juriPopMax =
        juriPopStatuses?.['Conjoints et 2 enfants ou plus à charge']
    if (min > juriPopMax) {
        return false
    }
    return true
}

const isJuriPopEligible = (province, revenueRange, status) => {
    if (isLegalAidEligible(province, revenueRange, status) === false) {
        const { min, max } = revenueRanges?.[revenueRange]
        const juriPopMax = juriPopStatuses?.[status]

        if (juriPopMax >= max) {
            return true
        } else if (juriPopMax <= min) {
            return false
        }
    }
    return undefined
}

const JuridicalHelp = ({
    values,
    handleChange,
    saveFieldState,
    province = 'QC',
    simple,
}) => {
    const {
        thinks,
        hasLowRevenue,
        status,
        revenueRange,
        privateLawyer,
        showJuriPop,
        discountEligible,
    } = values
    const { t, i18n } = useTranslation()
    const locale = i18n.language === 'en' ? 'en-CA' : 'fr-CA'
    const [simpleAJ, setSimpleAJ] = useState(undefined)
    const handleSimpleAJChange = (_, value) => {
        setSimpleAJ(value)
        if (value === 'yes') {
            handleChange('revenueRange', 1)
            handleChange('hasLowRevenue', true)
            handleChange('thinks', 'yes')
        }
        if (value === 'no') {
            handleChange('revenueRange', 0)
            handleChange('hasLowRevenue', false)
            handleChange('thinks', 'no')
        }
    }

    return simple ? (
        <Grid item xs={12} className="legal-aid-section">
            <span
                style={{
                    display: 'block',
                    fontSize: '0.95rem',
                    marginBottom: '0.2rem',
                }}
            >
                Êtes-vous en état de pauvreté vous permettant d’avoir accès à
                l’aide juridique?
            </span>
            <ButtonRow
                value={simpleAJ}
                saveFieldState={handleSimpleAJChange}
                options={[
                    { value: 'yes', label: 'Oui' },
                    { value: 'no', label: 'Non' },
                ]}
            />
            <small
                style={{
                    display: 'block',
                    fontSize: '0.75rem',
                    marginTop: '0.2rem',
                }}
            >
                (Information requise si vous voulez l'aide juridique)
            </small>
        </Grid>
    ) : (
        <Grid container spacing={2} className="legal-aid-section">
            <Grid item xs={12} sm={10} style={{ margin: '0 auto' }}>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        helperText={t('Vous devez sélectionner une province.')}
                        id="province"
                        name="province"
                        className="textInput"
                        label={t('Votre province')}
                        autoComplete="off"
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            height: '70px',
                        }}
                        onChange={saveFieldState}
                        {...province}
                    >
                        <MenuItem key={'QC'} value={'QC'}>
                            Québec
                        </MenuItem>
                        <MenuItem key={'ON'} value={'ON'}>
                            Ontario
                        </MenuItem>
                    </TextField>
                    <TextField
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            height: '70px',
                            marginBottom: '10px',
                        }}
                        select
                        fullWidth
                        id="revenueRange"
                        name="revenueRange"
                        className="textInput"
                        label={t('Votre revenu')}
                        autoComplete="off"
                        value={revenueRange}
                        onChange={(name, value) => handleChange(name, value)}
                    >
                        {revenueRangesOrderedKeys.map((r) =>
                            !revenueRanges[r].deprecated ? (
                                <MenuItem
                                    key={revenueRanges[r].label}
                                    value={revenueRanges[r].value}
                                >
                                    {t(revenueRanges[r].label)}
                                </MenuItem>
                            ) : null
                        )}
                    </TextField>
                </Grid>
                {province.value &&
                province.value !== '' &&
                revenueRange !== '' ? (
                    <>
                        {mightBeEligible(province.value, revenueRange) ? (
                            <Grid item xs={12}>
                                <TextField
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        height: '70px',
                                    }}
                                    select
                                    fullWidth
                                    id="status"
                                    name="status"
                                    className="textInput"
                                    label={t(
                                        'Quelle situation familial vous représente le mieux?'
                                    )}
                                    autoComplete="off"
                                    value={status}
                                    onChange={(name, value) => {
                                        handleChange(name, value)

                                        if (
                                            province.value === 'QC' &&
                                            isJuriPopEligible(
                                                province,
                                                revenueRange,
                                                value
                                            )
                                        ) {
                                            handleChange(
                                                'discountEligible',
                                                true
                                            )
                                        }
                                    }}
                                >
                                    {Object.keys(statuses[province.value]).map(
                                        (item) => (
                                            <MenuItem key={item} value={item}>
                                                {t(item)}
                                            </MenuItem>
                                        )
                                    )}
                                </TextField>
                            </Grid>
                        ) : null}
                        {statuses[province.value] &&
                        mightBeEligible(province.value, revenueRange) ? (
                            <>
                                {statuses[province.value][status] &&
                                showAmountsButtons(
                                    province.value,
                                    revenueRange,
                                    status
                                ) ? (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: 'left' }}
                                    >
                                        <span>
                                            <Trans i18nKey="familyRevenue">
                                                Quel montant approximatif
                                                représente le mieux votre revenu
                                                <strong> familial</strong>
                                            </Trans>
                                        </span>
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{ marginTop: '5px' }}
                                        >
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={() =>
                                                        handleChange(
                                                            'discountEligible',
                                                            true
                                                        )
                                                    }
                                                    color={
                                                        discountEligible
                                                            ? 'secondary'
                                                            : 'primary'
                                                    }
                                                    variant="contained"
                                                >
                                                    <Trans>Moins de</Trans>{' '}
                                                    {currency(
                                                        juriPopStatuses[
                                                            status
                                                        ] * 100,
                                                        locale
                                                    )}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={() =>
                                                        handleChange(
                                                            'discountEligible',
                                                            false
                                                        )
                                                    }
                                                    color={
                                                        discountEligible ===
                                                        false
                                                            ? 'secondary'
                                                            : 'primary'
                                                    }
                                                    variant="contained"
                                                >
                                                    {currency(
                                                        juriPopStatuses[
                                                            status
                                                        ] * 100,
                                                        locale
                                                    )}{' '}
                                                    <Trans>ou plus</Trans>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    showRevenueLowerThan(
                                        province.value,
                                        revenueRange,
                                        status
                                    ) && (
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ textAlign: 'left' }}
                                        >
                                            <span>
                                                <Trans>
                                                    Mon revenue est inférieur à
                                                </Trans>{' '}
                                                {currency(
                                                    statuses[province.value][
                                                        status
                                                    ] * 100,
                                                    locale
                                                )}
                                            </span>
                                            <Grid
                                                container
                                                spacing={2}
                                                style={{ marginTop: '5px' }}
                                            >
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={() =>
                                                            handleChange(
                                                                'hasLowRevenue',
                                                                true
                                                            )
                                                        }
                                                        color={
                                                            hasLowRevenue
                                                                ? 'secondary'
                                                                : 'primary'
                                                        }
                                                        variant="contained"
                                                    >
                                                        <Trans>Oui</Trans>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={() =>
                                                            handleChange(
                                                                'hasLowRevenue',
                                                                false
                                                            )
                                                        }
                                                        color={
                                                            hasLowRevenue ===
                                                            false
                                                                ? 'secondary'
                                                                : 'primary'
                                                        }
                                                        variant="contained"
                                                    >
                                                        <Trans>Non</Trans>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </>
                        ) : null}
                        {(isLegalAidEligible(
                            province.value,
                            revenueRange,
                            status
                        ) === false &&
                            status !== '') ||
                        hasLowRevenue === false ||
                        mightBeEligible(province, revenueRange) === false ? (
                            <Grid
                                item
                                container
                                xs={12}
                                spacing={2}
                                style={{ marginTop: '10px' }}
                            >
                                <Grid item xs={12}>
                                    <strong>
                                        {t('notAdmissibleToLegalAid')}
                                    </strong>
                                </Grid>
                            </Grid>
                        ) : null}
                        {showLegalAidChoice(
                            province.value,
                            revenueRange,
                            status
                        ) &&
                        !showAmountsButtons(
                            province.value,
                            revenueRange,
                            status
                        ) &&
                        (hasLowRevenue ||
                            isLegalAidEligible(
                                province.value,
                                revenueRange,
                                status
                            ) === true) ? (
                            <Grid item xs={12}>
                                <p>
                                    <strong>
                                        <Trans>
                                            Voulez-vous un avocat d'aide
                                            juridique
                                        </Trans>
                                        ?
                                    </strong>
                                </p>
                                {province.value === 'QC' ? (
                                    <p
                                        style={{
                                            fontWeight: 600,
                                            color: 'red',
                                        }}
                                    >
                                        <Trans i18nKey="legalAidWarning">
                                            Selon le revenu déclaré vous êtes
                                            admissible à l’aide juridique, il
                                            est toutefois souvent difficile de
                                            trouver des avocats qui acceptent
                                            des mandats d’aide juridique
                                            disponibles, vous pouvez donc
                                            choisir d’être mis en relation avec
                                            un avocat privé et payer directement
                                            ses frais?
                                        </Trans>
                                    </p>
                                ) : null}
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ marginBottom: '20px' }}
                                >
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color={
                                                privateLawyer === false
                                                    ? 'secondary'
                                                    : 'default'
                                            }
                                            onClick={() =>
                                                handleChange(
                                                    'privateLawyer',
                                                    false
                                                )
                                            }
                                        >
                                            <Trans i18nKey="wantLegalAid">
                                                Je veux l'aide juridique
                                            </Trans>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color={
                                                privateLawyer
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                            onClick={() =>
                                                handleChange(
                                                    'privateLawyer',
                                                    true
                                                )
                                            }
                                        >
                                            <Trans>
                                                Je veux un avocat privé
                                            </Trans>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}{' '}
                    </>
                ) : null}
            </Grid>
        </Grid>
    )
}

export default JuridicalHelp
