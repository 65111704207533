import React from 'react'

import styled from 'styled-components'

import Button from '../Button'
import arrow from '../../../static/06-2020/Jurigo-11.svg'

// prettier-ignore
const Container = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    ${({ fullWidth }) => !fullWidth && 
    `@media (max-width: 767px) {
        justify-content: flex-start;
        button {
            margin: 0;
            width: 92%;
        }
    }`}
    && {
        img {
            margin: ${(props) =>
                !props.fullWidth ? `0 -2.5rem -3rem 0` : `inherit`};
            position: absolute;
            right: 0px;
            width: 30px;
            bottom: 70px;
            z-index: 10;
            @media (max-width: 767px) {
                margin: ${(props) =>
                    !props.fullWidth ? `0px -0.6rem -3rem 0px` : `inherit`};
            }
        }
    }
`

const Img = styled.img``

const ButtonWithArrow = ({ onClick, children, fullWidth, disabled }) => (
    <Container fullWidth={fullWidth}>
        <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Button>
        <Img src={arrow} fullWidth={fullWidth} alt="" />
    </Container>
)

export default ButtonWithArrow
